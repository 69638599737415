/* eslint-disable */
$(function () {
    const lineNum = 4;

    $('.b-top-card__summary').each((i, el) => {
        const $el = $(el);
        const $wrapper = $('<span class="' + 'b-top-card__ellip-wrapper' + '" />').text($el.text());

        let currentOffset = null;
        let currLine = 0;
        let startEllipAt = 0;

        $el.empty().append($wrapper);

        const words = $.trim($wrapper.text()).split(/\s+/);
        const span = '<span style="white-space: nowrap;">';

        $wrapper.html(span + words.join('</span> ' + span) + '</span>');

        $wrapper.find('span').each(function (i, word) {
            const $word = $(word);
            const top = $word.position().top;

            if (top !== currentOffset) {
                currentOffset = top;
                currLine += 1;
            }
            if (currLine === lineNum) {
                startEllipAt = i;
                return false;
            }
        });

        if (currLine >= lineNum) {
            words[startEllipAt] = '<span class="' + 'b-top-card__ellip-line' + '">' + words[startEllipAt];
            words.push('</span>');
        }

        $wrapper.html(words.join(' '));
    });
});
