import Cookie from 'js-cookie'
import Vue from 'components'
import vuetify from 'modules/vuetify-plugin-without-reset-scss'
import ConfirmTown from 'components/common/ConfirmTown/components/ConfirmTown'
import { COOKIE_KEY, MOUNT_SELECTOR } from 'components/common/ConfirmTown/constants'

const el = document.querySelector(MOUNT_SELECTOR)
const isTownConfirmed = Cookie.get(COOKIE_KEY)

if (el && !isTownConfirmed) {
  new Vue({
    vuetify,
    el,
    components: {
      ConfirmTown,
    },
  })
}
