/* eslint-disable */
$(window).on('load', function () {
    const $message = $('.b-feed__expand-message');
    const messageHidden = $('.b-feed__expand-message').html();
    const messageExpanded = "Скрыть";

    let hidden = true;

    const titleWidth = $('.b-feed__title').innerWidth();

    $('.b-feed__title').each(function (i, el) {
        $(el).innerWidth(titleWidth);
    });

    $('.b-feed__expand').on('click', function (event) {
        $('.b-feed__item_hidden').each(function (i, el) {
            $(el).toggle();
        });
        hidden = !hidden;
        if (hidden) {
            $message.html(messageHidden);
        } else {
            $message.html(messageExpanded);
        }
        $('.b-feed__link').each(makeEllip);
    });

    const lineNum = 2;
    function makeEllip (i, el) {
        const $el = $(el);
        $el.html($el.text());
        const $wrapper = $('<span class="' + 'b-feed__ellip-wrapper' + '" />').text($el.text());

        let currentOffset = null;
        let currLine = 0;
        let startEllipAt = 0;

        $el.empty().append($wrapper);

        const words = $.trim($wrapper.text()).split(/\s+/);
        const span = '<span style="white-space: nowrap;">';

        $wrapper.html(span + words.join('</span> ' + span) + '</span>');

        $wrapper.find('span').each(function (i, word) {
            const $word = $(word);
            const top = $word.position().top;

            if (top !== currentOffset) {
                currentOffset = top;
                currLine += 1;
            }

            if (currLine === lineNum) {
                startEllipAt = i;
                return false;
            }
        });

        if (currLine >= lineNum) {
            words[startEllipAt] = '<span class="' + 'b-feed__ellip-line">' + words[startEllipAt];
            words.push('</span>');
        }

        $wrapper.html(words.join(' '));
    }

    $('.b-feed__link').each(makeEllip);
});
