/* eslint-disable */
// var openMenu = function (e) {
//     e.preventDefault();
//     e.stopPropagation();
//     $('.user_menu_elem div.user-menu-top__prof').hide();
//     $('.user-menu-top, .user_menu_elem').removeClass('opened');
//     updateLpuMainMenuSize();
//     var obj = $(this).data("obj");
//
//     function activateSubmenu(row) {
//         var $row = $(row),
//             $submenu = $row.children('ul');
//
//         $row.children('a').addClass('hover');
//         $other_menu = $row.siblings('li').children('ul');
//         $submenu.css({
//             display: 'block'
//         });
//         $row.siblings('li').children('a').removeClass('hover');
//         $other_menu.css('display', 'none');
//     }
//
//     function deactivateSubmenu(row) {
//         var $row = $(row),
//             $submenu = $row.children('ul');
//
//         $row.find('a').removeClass('hover');
//         $submenu.css('display', 'none');
//     }
// };

$(document).on('click', '.select_prof_lpu', function (e) {
  e.preventDefault()
  $.ajax({
    url: '/profile/lpus/ajax/set_lpu/',
    type: 'post',
    data: {
      csrfmiddlewaretoken: csrftoken,
      lpu: $(this).data('lpu'),
    },
    success(data) {
      const parsed = JSON.parse(data)

      if (parsed.msg === 'OK') {
        if (window.location.href.search('profile/') > -1) {
          window.location = window.location.href.split('?')[0]
        } else {
          $('.user-menu-top').html(parsed.rendered)
        }
      } else {
        alert('Произошла ошибка')
      }
    },
    error() {
      alert('Произошла ошибка')
    },
  })
})

function updateLpuMainMenuSize() {
  if ($('.user_menu_town_name').length) {
    $('.user-menu-top').height(45)
  } else {
    $('.user-menu-top').height(38)
  }
}

$('.user_menu_elem').click(function (e) {
  // e.preventDefault();
  $('.user-menu-top').removeClass('opened')
  updateLpuMainMenuSize()

  if ($(e.target).closest('.user-menu-top__prof').length < 1) {
    $('.user_menu_elem').not(this).children('div.user-menu-top__prof').hide()
    $('.user_menu_elem').not(this).removeClass('opened')
    $('div.user-menu-top__prof', this).toggle()

    if ($('div.user-menu-top__prof', this).is(':visible')) {
      $(this).addClass('opened')
    } else {
      $(this).removeClass('opened')
    }
  }
})

$(document).click(e => {
  if ($(e.target).closest('.user-menu-top__prof').length < 1 && $(e.target).closest('.user_menu_elem').length < 1) {
    $('.user_menu_elem div.user-menu-top__prof').hide()
    $('.user-menu-top, .user_menu_elem').removeClass('opened')
    updateLpuMainMenuSize()
  }
})

$('.user-menu-top ').click(function (e) {
  $('.user_menu_elem div.user-menu-top__prof').hide()
  $('.user_menu_elem').removeClass('opened')
  $(this).addClass('opened')

  if ($(this).hasClass('opened')) {
    let topSize

    if ($('.user-menu-top__lpu').length) {
      topSize = 379
    } else {
      topSize = $(this).hasClass('user-menu-top__patient') ? 80 : 140
    }

    topSize = $(this).hasClass('must_load') ? 60 : topSize

    if ($('.user-menu-top__doctor').length) {
      topSize = 192
    }

    $('.user-menu-top.opened').height(topSize + $('.user-menu-top__bottom').height())
  } else {
    updateLpuMainMenuSize()
  }
})

updateLpuMainMenuSize()
