var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VApp", { attrs: { "data-confirm-town": "" } }, [
    _c(
      "div",
      {
        staticClass: "b-container confirm-town",
        class: {
          "confirm-town_mobile": _vm.isMobile,
          "confirm-town_desktop": !_vm.isMobile,
        },
      },
      [
        _c(
          "VBanner",
          {
            staticClass: "confirm-town__banner",
            class: { "mr-4": !_vm.isMobile },
            attrs: {
              width: _vm.isMobile ? "auto" : 280,
              color: "uiKitBgGray0",
              elevation: "8",
              rounded: "",
            },
            model: {
              value: _vm.isVisible,
              callback: function ($$v) {
                _vm.isVisible = $$v
              },
              expression: "isVisible",
            },
          },
          [
            _c(
              "div",
              { staticClass: "ui-text ui-text_body-2 ui-kit-color-text mb-2" },
              [
                _vm._v(
                  "\n        Ваш город — " + _vm._s(_vm.townName) + "?\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-end" },
              [
                _c(
                  "VBtn",
                  {
                    staticClass: "button-reset mr-2",
                    attrs: { color: "uiKitText", text: "", small: "" },
                    on: { click: _vm.handleClickChange },
                  },
                  [_vm._v("\n          Изменить\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "VBtn",
                  {
                    staticClass: "button-reset",
                    attrs: { color: "primary", small: "", depressed: "" },
                    on: { click: _vm.handleClickConfirm },
                  },
                  [_vm._v("\n          Верно\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }