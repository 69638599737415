/* eslint-disable */
$(() => {
  require('libs/magnific-popup/jquery.magnific-popup.min.js')

  $('.b-popup-gallery').each(function () {
    let type = 'image'
    let mainClass = 'b-popup-gallery__popup'

    if ($(this).hasClass('b-popup-gallery_type_video')) {
      type = 'iframe'
      mainClass += ' b-popup-gallery__popup_iframe'
    }

    $(this).magnificPopup({
      delegate: '.b-popup-gallery__preview',
      mainClass,
      type,
      removalDelay: 200,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        arrowMarkup: '<button title="%title%" type="button" class="b-popup-gallery__arrow mfp-arrow-%dir%"></button>',
      },
      closeMarkup: '<button title="%title%" type="button" class="b-popup-gallery__close-btn mfp-close"></button>',
      image: {
        tError: 'не удается загрузить <a href="%url%">изображение</a>.',
        verticalFit: false,
        titleSrc(item) {
          return item.el[0].title
        },
        markup:
                    `
                    <div class="b-popup-gallery__wrapper mfp-with-anim">
                        <div class="mfp-close"></div>
                        <div class="mfp-img"></div>
                        <div class="mfp-bottom-bar">
                            <div class="mfp-title"></div>
                        </div>
                    </div>
                    `,
      },
      iframe: {
        markup:
                  `
                    <div class="b-popup-gallery__wrapper mfp-with-anim">
                        <div class="mfp-close"></div>
                        <iframe class="mfp-iframe" width="900" height="500" frameborder="0" allowfullscreen></iframe>
                        <div class="mfp-bottom-bar">
                            <div class="mfp-title"></div>
                        </div>
                     </div>
                    `,
      },
      callbacks: {
        imageLoadComplete() {
          makeEllip($(this.content).find('.mfp-title'))
        },
        markupParse(template, values, item) {
          if (type === 'iframe') {
            if (item.el.attr('title')) {
              values.title = item.el.attr('title')
            } else {
              template.addClass('b-popup-gallery__wrapper_hidden-title')
            }
          }

          if (!values.title) {
            template.addClass('b-popup-gallery__wrapper_hidden-title')
          } else {
            template.removeClass('b-popup-gallery__wrapper_hidden-title')
          }
        },
        beforeOpen() {
          this.st.mainClass += ' mag-popup'
          this.st.mainClass += ` ${this.st.el.attr('data-effect')}`
        },
      },
    })
  })

  function makeEllip(el) {
    const lineNum = 2
    const $el = $(el)

    $el.html($el.text())

    const $wrapper = $('<span class="' + 'ellip-wrapper' + '" />').text($el.text())

    let currentOffset = null
    let currLine = 0
    let startEllipAt = 0

    $el.empty().append($wrapper)

    const words = $.trim($wrapper.text()).split(/\s+/)
    const span = '<span style="white-space: nowrap;">'

    $wrapper.html(`${span + words.join(`</span> ${span}`)}</span>`)

    $wrapper.find('span').each((i, word) => {
      const $word = $(word)
      const { top } = $word.position()

      if (top !== currentOffset) {
        currentOffset = top
        currLine += 1
      }

      if (currLine === lineNum) {
        startEllipAt = i
        return false
      }
    })

    if (currLine >= lineNum) {
      words[startEllipAt] = `<span class="ellip-line">${words[startEllipAt]}`
      words.push('</span>')
    }

    $wrapper.html(words.join(' '))
  }
})
